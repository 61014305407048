import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/posts-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`(Blog post content, components, etc.)
我是一些测试的中文。
asdas
你好`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.body {
  padding: 1px red solid;
}
`}</code></pre>
    <p>{`Here’s to the crazy ones.`}</p>
    <p>{`The misfits. The rebels. The troublemakers. The round pegs in the square holes. The ones who see things differently.
They’re not fond of rules. And they have no respect for the status quo. You can quote them, disagree with them, glorify or vilify them. About the only thing you can’t do is ignore them.
Here’s to the crazy ones.`}</p>
    <p>{`The misfits. The rebels. The troublemakers. The round pegs in the square holes. The ones who see things differently.
They’re not fond of rules. And they have no respect for the status quo. You can quote them, disagree with them, glorify or vilify them. About the only thing you can’t do is ignore them.
Because they change things. They push the human race forward. And while some may see them as the crazy ones, we see genius. Because the people who are crazy enough to think they can change the world, are the ones who do.`}</p>
    <h2>{`你好`}</h2>
    <h1></h1>
    <p>{`每当看到“GQ 实验室”的推送，我们都会对文章的脑洞感到震惊，小编到底要有多少生活经验、多少知识储备才能写出如此有料有趣的文章。`}</p>
    <p>{`其实，“GQ 实验室”的稿件几乎都是多人分工撰写的，每篇文章的协同编辑人数视情况而定，几人到十几人不等。在开始写作前，编辑们会先用石墨文档列一个内容提纲，然后分配任务，各自撰写领到的内容。就这样，编辑们在文档里“扮演”同一个人，通力协作，最终完成一篇篇 10W+爆款文章。`}</p>
    <p>{`由于石墨文档的内容是实时更新的，在撰写内容的时候，编辑们可以实时互相看到对方的进度，有时候，拖稿的编辑还会被同事开玩笑，直接用红色特大号字在文档里面 diss。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      